import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {ApplicationStatusesEnum} from '../../../../models/mfc/enums/application-statuses.enum';
import {lastValueFrom} from 'rxjs';
import {ApplicationTypeSelectService} from '../../../../services/mfc/application-type-select.service';
import {ApplicationCategoryTree} from '../../../../models/mfc/application-categories-tree.model';
import {ApplicationStatusService} from '../../../../services/mfc/dicts/application-status.service';
import {
  ApplicationStatus,
  displayFIOStatuses
} from '../../../../models/mfc/dicts/application-status.model';
import {Tab, tabs} from '../../../../models/mfc/applicationForm/application-tabs.model';
import {ApplicationTabsEnum} from '../../../../models/mfc/enums/application-tabs.enum';
import {
  ApplicationConstructorSettings
} from '../../../../models/mfc/applicationConstructor/application-constructor-settings.model';
import {TabsSettings} from '../../../../models/mfc/applicationConstructor/tabs-settings.model';
import {SelectEvent} from '@progress/kendo-angular-layout';
import {DocumentTypes} from '../../../../models/mfc/enums/document-types.enum';
import {ConstructorDocument} from '../../../../models/mfc/applicationConstructor/constructor-document.model';
import {saveAs} from '@progress/kendo-file-saver';
import {
  ApplicationForm,
  ApplicationNumber,
  CreateApplicationMainForm,
  EditApplicationMainForm,
  GetApplication
} from '../../../../models/mfc/applicationForm/application-form.model';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {ApplicationConstructorDocumentService} from '../../../../services/mfc/application-constructor-document.service';
import {NotificationsService} from '../../../../services/Notifications/notifications.service';
import {ApplicationConstructorService} from '../../../../services/mfc/application-constructor.service';
import {openDialog} from '../../../../helpers/dialog-helper';
import {ApplicationFormService} from '../../../../services/mfc/application-form.service';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';
import {AllowedActions} from '../../../../models/mfc/enums/allowed-actions.enum';
import {TypeReceipt} from '../../../../models/mfc/dicts/type-receipt.model';
import {TypeReceiptService} from '../../../../services/mfc/dicts/type-receipt.service';
import {ApplicationDocumentList} from '../../../../models/mfc/applicationForm/document.model';
import {DocumentsService} from '../../../../services/mfc/documents.service';
import {ReceiptTypesEnum} from '../../../../models/mfc/enums/receipt-types.enum';
import {createMap} from '../../../../helpers/map-helper';
import {EnableDisciplineTable} from "../../../../models/mfc/enums/enable-discipline-table.enum";
import {InWorkInfo} from "../../../../models/mfc/applicationForm/approvalList.model";


@Component({
  selector: 'mfc-application-form-home',
  templateUrl: './application-form-home.component.html',
  styleUrls: ['./application-form-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormHomeComponent implements OnInit, OnDestroy, AfterViewChecked {

  private id = this.activatedRoute.snapshot.params['id'];
  protected editable = false;
  protected isAdd = !this.id;

  protected currentTab = this.editFormService.currentTab;
  private hasChanges = false;

  protected statusText = '';
  protected typeTooltip = '';
  protected tabs: Tab[] = [];
  protected application?: GetApplication;
  protected applicationConstructor?: ApplicationConstructorSettings;
  protected documents: ApplicationDocumentList = new ApplicationDocumentList();
  protected typeReceiptName?: string;
  protected receiptTypeExplanation?: string;
  protected readonly relatedApplicationsDescription =
    `В данном поле при необходимости можно выбрать связанные с данной заявкой заявки.
    В заявке будут указаны ссылки на указанные в данном поле заявки.`;

  protected applicationCategoriesTree: ApplicationCategoryTree[] = [];
  protected applicationCategory?: ApplicationCategoryTree;
  protected statuses: ApplicationStatus[] = [];
  protected dictTypeReceipts: TypeReceipt[] = [];
  protected filteredTypeReceipt: TypeReceipt[] = [];
  protected applicationNumbers: ApplicationNumber[] = [];
  protected applicationNumbersMap: Map<string, string> = new Map([]);
  private hasDisciplineSemesters = false;
  protected inWork = false;
  protected lastInWorkInfo?: InWorkInfo;

  protected formGroup = new FormGroup({});
  protected filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  protected allowedActions = new Map<AllowedActions, boolean>([
    [AllowedActions.Save, false],
    [AllowedActions.Delete, false],
    [AllowedActions.Withdrawn, false]
  ]);

  protected readonly DocumentTypes = DocumentTypes;
  protected readonly AllowedActions = AllowedActions;
  protected readonly ApplicationTabsEnum = ApplicationTabsEnum;
  protected readonly ReceiptTypesEnum = ReceiptTypesEnum;
  protected readonly displayFIOStatuses = displayFIOStatuses;
  protected readonly ApplicationStatusesEnum = ApplicationStatusesEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private applicationTypeSelectService: ApplicationTypeSelectService,
    private applicationStatusService: ApplicationStatusService,
    private constructorDocumentService: ApplicationConstructorDocumentService,
    private documentsService: DocumentsService,
    private editFormService: ApplicationEditFormService,
    private applicationConstructorService: ApplicationConstructorService,
    private applicationFormService: ApplicationFormService,
    private typeReceiptService: TypeReceiptService
  ) {
    if (this.isAdd) this.formGroup = this.createAddFormGroup();
  }

  async ngOnInit() {
    await this.getApplicationNumbers();
    if (this.isAdd) {
      this.getTypeReceipts();
      this.editable = true;
      this.allowedActions.set(AllowedActions.Save, this.editable);
      await this.getCategoriesTree();
      this.activatedRoute.queryParams.subscribe((params) => {
        this.patchApplicationForm(params['categoryId'], params['typeId']);
      });
    } else {
      await this.getStatuses();
      this.getApplication();
    }
  }

  ngAfterViewChecked() {
    document.querySelectorAll('input.k-input-inner').forEach((el) => {
      el.setAttribute('autocomplete', 'none');
    });
  }

  public typeReceiptChanged(id?: string) {
    this.receiptTypeExplanation = this.applicationConstructor?.basicSettings?.typeReceiptExplanations.
      find(typeReceipt => typeReceipt.dictTypeReceiptId === id)?.text;
  }

  private async getCategoriesTree() {
    await lastValueFrom(this.applicationTypeSelectService.getCategoriesTree()).then((response) => {
      this.applicationCategoriesTree = response;
    });
  }

  private async getStatuses() {
    await lastValueFrom(this.applicationStatusService.getAll()).then((response) => {
      this.statuses = response;
    });
  }

  private getTypeReceipts() {
    this.typeReceiptService.getAll().subscribe((response) => {
      this.dictTypeReceipts = response;
      this.filteredTypeReceipt = response;
    });
  }

  private async getApplicationNumbers() {
    await this.applicationFormService.getApplicationNumbers().subscribe( (response) => {
      this.applicationNumbers = response;
      this.applicationNumbersMap = createMap(
        {value: response, valueField: 'applicationId', textField: 'applicationNumber'});
    });
  }

  private getApplication() {
    this.applicationFormService.getApplicationById(this.id).subscribe({
      next: async (response) => {
        this.application = response;
        this.receiptTypeExplanation = response.receiptTypeExplanation;
        this.statusText = this.statuses.find((item) =>
          item.applicationStatusEnum === this.application?.statusEnum)?.name ?? '';
        this.typeReceiptName = response.dictReceiptType.name;
        this.editFormService.disciplineTableSemesters = response.disciplineTableSemesters
        this.editable = this.editFormService.isApplicationEditable(this.application.statusEnum);
        this.editFormService.enableDisciplineTable = response.enableDisciplineTable;
        this.editFormService.studentId = response.student.id;
        this.setAllowedActions(this.application.statusEnum);

        this.editFormService.inWork = response.approvalSettings.some(item => item.inWorkInfo);
        this.inWork = this.editFormService.inWork;

        this.lastInWorkInfo = response.approvalSettings
          .flatMap(item => item.inWorkInfo)
          .filter((o): o is InWorkInfo => !!o)
          .map(item => ({...item, dateTime: new Date(item.dateTime)}))
          .find(item => Math.max(item.dateTime.getTime()));

        this.formGroup = this.createEditFormGroup();

        this.patchTabs();
        const dynamicStartIndex = this.tabs.findIndex((tab) => tab.selector === ApplicationTabsEnum.Dynamic);
        this.editFormService.setTabsData(this.application.tabs, dynamicStartIndex);
        this.cdRef.detectChanges();
      },
      error: (error) => {
        this.notificationsService.showError(error);
        this.router.navigateByUrl('/mfc');
      },
    });
  }

  private refreshNotificationCount() {
    if (this.application?.notificationCount) this.application.notificationCount = 0;
  }

  private getApplicationConstructor(typeId: string) {
    this.applicationConstructorService.getApplicationConstructor(typeId).subscribe({
      next: (response) => {
        this.applicationConstructor = response;
        const dictTypeReceiptIds = response.basicSettings.dictTypeReceiptIds;
        if (dictTypeReceiptIds.length === 1) {
          (<AbstractControl>this.formGroup.get('dictTypeReceiptId')).patchValue(dictTypeReceiptIds[0]);
        }
        this.filteredTypeReceipt = this.dictTypeReceipts.filter((item) => dictTypeReceiptIds.includes(item.id));
        this.typeReceiptName = this.dictTypeReceipts
          .find(typeReceipt => typeReceipt.id === dictTypeReceiptIds[0])?.name;
        this.receiptTypeExplanation = this.applicationConstructor.basicSettings.typeReceiptExplanations
          .find((item) => item.dictTypeReceiptId === this.filteredTypeReceipt[0].id)?.text ?? '';
        this.hasDisciplineSemesters = [EnableDisciplineTable.BySemester, EnableDisciplineTable.BySemesterPractice]
         .includes(this.applicationConstructor?.basicSettings.enableDisciplineTable ?? EnableDisciplineTable.No);
        this.editFormService.enableDisciplineTable = this.applicationConstructor?.basicSettings.enableDisciplineTable;

        const disciplineTab = tabs.find((tab) => tab.selector === ApplicationTabsEnum.Discipline);
        if (disciplineTab) {
          disciplineTab.displayInAddForm = this.hasDisciplineSemesters;
        }

        this.patchTabs();
        this.cdRef.detectChanges();
      },
      error: () => this.notificationsService.showError('Вы не можете создать заявку такого типа'),
    });
  }

  private setAllowedActions(status: ApplicationStatusesEnum) {
    this.allowedActions = new Map<AllowedActions, boolean>([
      [AllowedActions.Save, this.editable],
      [AllowedActions.Delete, [ApplicationStatusesEnum.Draft, ApplicationStatusesEnum.Withdrawn].includes(status)],
      [AllowedActions.Withdrawn, [ApplicationStatusesEnum.Sent, ApplicationStatusesEnum.Revision].includes(status)]
    ]);
  }

  private createAddFormGroup() {
    const form = new CreateApplicationMainForm();
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(form).forEach((key) => {
      arr[key] = new FormControl({
        value: form[key as keyof typeof form],
        disabled: !this.isAdd
      });
    });
    return new FormGroup(arr);
  }

  private createEditFormGroup() {
    const form = new EditApplicationMainForm();

    if (this.application?.relatedApplications?.length) {
      form.relatedApplicationIds = this.application.relatedApplications.map((item) => item.id);
      this.applicationNumbersMap = createMap(
        {value: this.application.relatedApplications, valueField: 'id', textField: 'applicationNumber'});
    } else if (this.application?.statusEnum !== ApplicationStatusesEnum.Draft
      && this.application?.statusEnum !== ApplicationStatusesEnum.Revision) {
      this.applicationNumbersMap.clear();
    }

    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(form).forEach((key) => {
      arr[key] = new FormControl(form[key as keyof typeof form]);
    });
    return new FormGroup(arr);
  }

  private patchApplicationForm(categoryId?: string, typeId?: string) {
    if (categoryId) {
      this.patchCategory(categoryId);
    }
    if (typeId) {
      (<AbstractControl<unknown, unknown>>this.formGroup.get('type'))?.patchValue(typeId);
      this.typeTooltip = this.getTooltip(typeId);
      this.typeChange(typeId);
    }
  }

  private patchCategory(id?: string) {
    (<AbstractControl<unknown, unknown>>this.formGroup.get('category'))?.patchValue(id);
    this.categoryChange(id);
  }

  private patchTabs() {
    let tabsSettings;
    if (this.isAdd) {
      this.tabs = [...tabs.filter(tab => tab.displayInAddForm)];
      tabsSettings = this.applicationConstructor?.tabs;
    } else {
      if (this.application?.documents) {
        const approvalDocuments = this.application.approvalSettings.map((item) =>
          item.applicationConstructorDocumentId).filter((id) => id) ?? [];
        this.documents.signature = this.application.documents.filter((doc) =>
          approvalDocuments.includes(doc.externalId)) ?? [];
        this.documents.ready = this.application.documents.filter((doc) =>
          doc.documentType === DocumentTypes.Document &&
          !approvalDocuments.includes(doc.externalId)) ?? [];
      }
      this.tabs = [...tabs.filter((tab) =>
        !tab.hideOnStatuses?.includes(this.application?.statusEnum ?? 0) &&
        (tab.selector !== ApplicationTabsEnum.Documents || this.documents.signature.length) &&
        (tab.selector !== ApplicationTabsEnum.ReadyDocuments || this.documents.ready.length) &&
        (tab.selector !== ApplicationTabsEnum.Discipline || this.application?.enableDisciplineTable !== EnableDisciplineTable.No))
      ];
      tabsSettings = this.application?.tabs;
    }
    tabsSettings?.forEach((tab: TabsSettings, index) => {
      const commentsIndex = this.tabs.findIndex((tab) => tab.selector === ApplicationTabsEnum.Comments);
      this.tabs.splice(commentsIndex > -1 ? commentsIndex : index, 0,
        {
          id: tab.externalId,
          name: tab.name,
          selector: ApplicationTabsEnum.Dynamic,
          displayInAddForm: true,
        });
    });
  }

  private getTooltip(typeId?: string) {
    return this.applicationCategory?.dictApplicationTypes.find(a => a.externalId === typeId)?.tooltip ?? '';
  }

  private resetField(resetCategory: boolean = false) {
    this.formGroup.get(resetCategory ? 'category' : 'type')?.reset();
    resetCategory ? this.categoryChange() : this.typeChange();
  }

  protected categoryChange(id?: string) {
    this.applicationCategory = this.applicationCategoriesTree.find((item) => item.externalId === id);
    const typeMatch = this.applicationCategory?.dictApplicationTypes.some((item) =>
      item.externalId === this.formGroup.get('type')?.value);
    if (!typeMatch) {
      this.resetField();
    }
  }

  protected typeChange(id?: string) {
    this.typeTooltip = this.getTooltip(id);
    if (id) {
      this.getApplicationConstructor(id);
    } else {
      this.applicationConstructor = undefined;
      this.typeReceiptName = undefined;
      this.tabs = [];
    }
  }

  protected downloadFile(document: ConstructorDocument) {
    this.constructorDocumentService.getDocument(
      this.applicationConstructor?.externalId ?? this.application?.applicationConstructorId ?? '',
      document.externalId
    ).subscribe({
      next: (response: Blob) => saveAs(response, document.name),
      error: (error) => this.notificationsService.showError(error.error.Message ?? error),
    });
  }

  protected goToApplication(id: string) {
    window.open(`/mfc/applicationForm/${id}`, '_blank');
  }

  protected onTabChange(source: SelectEvent) {
    if (this.currentTab === source.index) {
      return;
    }

    if (this.tabs[this.currentTab].selector === ApplicationTabsEnum.Dynamic) {
      this.editFormService.save$.next(this.currentTab);
    }

    this.currentTab = source.index;
    if (this.tabs[this.currentTab].selector === ApplicationTabsEnum.Comments) {
      this.refreshNotificationCount();
    }
  }

  protected goBack() {
    const isSaved = !(this.hasChanges || this.editFormService.hasFormChanges(this.currentTab));
    if (this.isAdd || isSaved) {
      this.editFormService.clearTabsData();
      this.router.navigateByUrl('/mfc');
    } else {
      const dialog = openDialog(this.dialogService, `Вы хотите закрыть форму без сохранения изменений?`);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.editFormService.clearTabsData();
          this.editFormService.resetHasChanges();
          this.router.navigateByUrl('/mfc');
        }
      });
    }
  }

  protected onSave(send: boolean = false) {
    const receiptType = this.formGroup.get('dictTypeReceiptId')?.value;
    if (this.isAdd && !receiptType) {
      this.notificationsService.showError('Выберите вариант получения');
      return;
    }

    this.editFormService.save$.next(this.currentTab);
    const form: ApplicationForm = {applicationPropertiesValueDto: this.editFormService.getTabsValues()};

    if (!this.editFormService.isValid) {
      this.notificationsService.showError('Заполните все обязательные поля');
      return
    }

    const relatedApplications = <string[]|undefined>this.formGroup.get('relatedApplicationIds')?.value;
    if (relatedApplications?.length) {
      form.relatedApplicationIds = relatedApplications;
    }

    if (this.isAdd) {
      form.dictTypeReceiptId = receiptType;
      form.applicationConstructorId = this.applicationConstructor?.externalId;
    }

    form.disciplineTableSemesters = this.editFormService.disciplineTableSemesters ?? [];

    this.applicationFormService.saveApplication(form, this.id).subscribe({
      next: (response) => {
        this.editFormService.clearTabsData();
        this.editFormService.resetHasChanges();

        if (send) {
          this.id = this.id ?? response;
          this.send();
        } else {
          this.notificationsService.showSuccess('Успешно');
          if (this.isAdd) {
            this.router.navigateByUrl(`/mfc`);
          } else {
            this.getApplication();
          }
        }
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  private send() {
    this.applicationFormService.changeStatus(this.id, ApplicationStatusesEnum.Sent).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Успешно');
        this.router.navigateByUrl(`/mfc`);
      },
      error: (error) =>  this.notificationsService.showError(error.error.error)
    });
  }

  protected onDelete() {
    const dialog = openDialog(this.dialogService, 'Удалить заявку?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.applicationFormService.deleteApplication(this.id).subscribe({
          next: () => {
            this.notificationsService.showSuccess('Успешно');
            this.editFormService.clearTabsData();
            this.editFormService.resetHasChanges();
            this.router.navigateByUrl('/mfc');
          },
          error: (error) => this.notificationsService.showError(error)
        });
      }
    });
  }

  protected ToWithDrawn() {
    const isSaved = !(this.hasChanges || this.editFormService.hasFormChanges(this.currentTab));
    if (isSaved) {
      const dialog = openDialog(this.dialogService,
        `Вы действительно хотите отозвать заявку? Заявка станет недоступна операторам для взятия в работу.`);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.applicationFormService.changeStatus(this.id, ApplicationStatusesEnum.Withdrawn).subscribe({
            next: () => {
              this.notificationsService.showSuccess('Успешно');
              this.getApplication();
            },
            error: (error) => this.notificationsService.showError(error)
          });
        }
      });
    } else {
      this.notificationsService.showError('Сохраните изменения');
    }
  }

  ngOnDestroy(): void {
    this.editFormService.disciplineTableSemesters = [];
  }
}
