import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {
  DisciplineTable,
  DisciplineTableByCycleType,
  DisciplineTableBySemester
} from "../../../../models/mfc/applicationForm/discipline-table.model";
import {CellClickEvent, GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {EnableDisciplineTable} from "../../../../models/mfc/enums/enable-discipline-table.enum";
import {process, State} from "@progress/kendo-data-query";
import {DictCycleType, dictCycleTypes} from "../../../../models/mfc/enums/cycle-type.enum";
import {DisciplineTableService} from "../../../../services/mfc/discipline-table.service";
import {StudentService} from "../../../../services/mfc/dicts/student.service";
import {lastValueFrom} from "rxjs";
import {DictMarkService} from "../../../../services/mfc/dicts/mark.service";
import {createMap} from "../../../../helpers/map-helper";
import {ApplicationEditFormService} from "../../../../services/mfc/application-edit-form.service";
import {Router} from "@angular/router";
import {CourseSemester, StudentSemester} from "../../../../models/mfc/student-semester.model";

@Component({
  selector: 'mfc-discipline-table',
  templateUrl: './discipline-table.component.html',
  styleUrls: ['./discipline-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisciplineTableComponent implements OnInit {
  @Input() editable = false;
  @Input() applicationId? = '';
  @Input() tabIndex = 0;
  @Input() studentId = '';

  protected gridView: GridDataResult = {data: [], total: 0};

  protected disciplineTableByCycleType: DisciplineTableByCycleType[] = [];
  protected disciplineTableBySemester: DisciplineTableBySemester[] = [];
  protected semesters: {id: number, name: string}[] = [];
  protected courses: CourseSemester[] = [];

  protected marksMap = new Map<unknown, unknown>([]);

  protected dictCycleTypes = dictCycleTypes;

  protected isSemester = false;
  protected studentSemesters: StudentSemester[] = [];
  protected studentPeriod: string = '';

  protected skip = 0;
  protected state: State = {
    skip: 0,
    take: 18
  };

  constructor(private cdr: ChangeDetectorRef,
              private disciplineTableService: DisciplineTableService,
              private studentService: StudentService,
              private dictMarkService: DictMarkService,
              protected editFormService: ApplicationEditFormService,
              private router: Router
  ) {}

  async ngOnInit() {
    this.isSemester = [EnableDisciplineTable.BySemester, EnableDisciplineTable.BySemesterPractice].includes(
      this.editFormService.enableDisciplineTable ?? EnableDisciplineTable.No);
    this.disciplineTableService.applicationId = this.applicationId;
    await this.getStudentCurrentSemester();

    if (this.applicationId) {
      this.getMarks();
      this.getDisciplines();
    }
  }

  protected pageChange(event: PageChangeEvent): void {
    this.state = event;
    this.processData();
  }

  private processData() {
    this.gridView = process(
      [...this.createGrouping(this.isSemester ? this.disciplineTableBySemester : this.disciplineTableByCycleType)],
      this.state
    );
    this.cdr.detectChanges();
  }

  private getMarks() {
    this.dictMarkService.getMarks().subscribe((response) => {
      this.marksMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private getDisciplines() {
    this.isSemester ? this.getRowsBySemester() : this.getRowsByCycleType();
  }

  private getRowsByCycleType() {
    this.disciplineTableService.getRowsByCycleType().subscribe((response) => {
      this.disciplineTableByCycleType = response;
      this.processData();
    });
  }

  private getRowsBySemester() {
    this.disciplineTableService.getRowsBySemester().subscribe((response) => {
      this.disciplineTableBySemester = response;
      this.processData();
    })
  }

  private async getStudentCurrentSemester() {
    await lastValueFrom(this.studentService.getSemesters(this.studentId)).then(response => {
      this.semesters = response.map(item => ({name: `${item.number} семестр`, id: item.number}));
      this.courses = [
        ...new Set(response.map(item => item.course))
      ].map(item => ({
        courseNumber: item,
        semesters: response.filter(semester => semester.course === item)
          .map(item => item.number)
      }));
      this.studentSemesters = response;
      this.onSemesterChange(this.editFormService.disciplineTableSemesters);
    });
  }

  private filterData<T extends {
    isGroup?: boolean;
    semester?: number;
    dictCycleTypeEnum?: number,
    sortNumber?: number;
    courseWorkOrCourseProject?: boolean
  }>
  (input: {
    data: T[];
    course?: CourseSemester;
    dictCycleTypeEnum?: number;
  }) {
    return input.data.filter((item) => {
      const isCorrectSemester = input.course ? input.course.semesters.includes(item.semester ?? 0) : true;
      const isNotGroup = !item.isGroup;
      const isCorrectCycleType = (input.dictCycleTypeEnum === DictCycleType.CourseWork)
        ? item.courseWorkOrCourseProject
        : item.dictCycleTypeEnum === input.dictCycleTypeEnum && !item.courseWorkOrCourseProject;

      return isCorrectSemester && isNotGroup && isCorrectCycleType;
    }).sort((a, b) => (a.sortNumber ?? 0) - (b.sortNumber ?? 0));
  }

  private handleGroupData<T extends DisciplineTable>(input: {data: T[], dictCycleTypeEnum?: number, course?: CourseSemester}) {
    const groupData = this.filterData(input);
    if (!groupData.length) {
      return {groupData: [], groupTotalValues: {laborIntensity: 0, hours: 0}};
    }
    return {filteredData: groupData, groupTotalValues: input.course ? {hours: 0, laborIntensity: 0} : this.getTotalValues(groupData)};
  }

  private groupCycleTypes(data: DisciplineTableByCycleType[]) {
    const groupData: DisciplineTableByCycleType[] = [];

    this.dictCycleTypes.forEach((item) => {
      const {filteredData, groupTotalValues} = this.handleGroupData({data: data, dictCycleTypeEnum: item.id});
      if (!filteredData?.length) {
        return;
      }

      groupData.push(...[
        {
          ...new DisciplineTableByCycleType(),
          dictCycleTypeEnum: item.id,
          name: item.text,
          laborIntensity: groupTotalValues.laborIntensity,
          hours: groupTotalValues.hours,
          isGroup: true
        }, ...filteredData]);
    });

    const totalValues = this.getTotalValues(groupData, true);

    const facultyIndex = groupData.findIndex((item) =>
      item.dictCycleTypeEnum === DictCycleType.FacultyDisciplines && item.isGroup);

    const sumItem = {
      ...new DisciplineTableByCycleType(),
      laborIntensity: totalValues.laborIntensity,
      hours: totalValues.hours,
      name: 'Итого',
      isGroup: true
    };

    facultyIndex >= 0 ? groupData.splice(facultyIndex, 0, sumItem) : groupData.push(sumItem);

    return groupData;
  }

  private groupSemesters(data: DisciplineTableBySemester[]) {
    const groupData: DisciplineTableBySemester[] = [];
    this.courses.forEach((course) => {
      const {filteredData} = this.handleGroupData({data: data, course: course});
      if (!filteredData?.length) {
        return;
      }

      groupData.push(...[
        {
          ...new DisciplineTableBySemester(),
          name: `${course.courseNumber} курс ${course.semesters.join(', ')} семестр`,
          isGroup: true
        }, ...filteredData]);
    });

    return groupData;
  }

  private createGrouping(data: unknown[]) {
    if (!data.length) {
      return [];
    }

    if (this.isSemester) {
      return this.groupSemesters(data as DisciplineTableBySemester[]);
    } else {
      return this.groupCycleTypes(data as DisciplineTableByCycleType[]);
    }
  }

  private getTotalValues<T extends DisciplineTable & {
    courseWorkOrCourseProject?: boolean
  }>(cycleData: T[], all: boolean = false) {
    return cycleData.filter((item) => {
      if (!all) return true;

      const equalDisciplines = cycleData.some((a) => a.dictDisciplineId === item.dictDisciplineId
        && (a.dictCycleTypeEnum !== DictCycleType.FacultyDisciplines && !a.courseWorkOrCourseProject)
      );
      const notFaculty = item.dictCycleTypeEnum !== DictCycleType.FacultyDisciplines;
      const isUnique = item.courseWorkOrCourseProject ? !equalDisciplines : true;
      const isValid = !item.isGroup && notFaculty;

      return isUnique && isValid;
    }).reduce((a, b) => ({
      hours: a.hours + b.hours,
      laborIntensity: Math.round((a.laborIntensity + b.laborIntensity) * 100) / 100
    }), {hours: 0, laborIntensity: 0});
  }

  protected onCellClick({dataItem, column}: CellClickEvent) {
    if (column.field === 'name' && dataItem.id && !window.getSelection()?.toString()) {
      localStorage.setItem('mfcDisciplineIndex', this.tabIndex.toString());
      this.router.navigate([`mfc/applicationForm/${this.applicationId}/discipline/${dataItem.id}`]);
    }
  }

  protected onSemesterChange(value?: number[]) {
    const dates = this.studentSemesters.filter(item => value?.includes(item.number))
      .map(item => ({begin: new Date(item.begin), end: new Date(item.end)}));

    if (!dates.length) {
      this.studentPeriod = '';
      return;
    }

    const minDate = Math.min(...dates.map(date => date.begin.getTime()));
    const maxDate = Math.max(...dates.map(date => date.end.getTime()));

    this.studentPeriod = `с ${new Date(minDate).toLocaleDateString()} по ${new Date(maxDate).toLocaleDateString()}`;
  }

}