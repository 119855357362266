import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {LessonModel, AttendedModel, PhysTrainingMarkModel, PhysSubject} from '../../models/progress/visitLesson.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentControlExtService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.currentcontrolext}`;

  constructor(private http: HttpClient) { }

    public getDisciplines(semester: number) {
      const data = {semester: semester};
      return this.http.post<{lecture: [], practice: []}>(this.baseUrl + '/LkoQuery', data);
    }

    public getLesson(controllingActionExternalId: string | undefined, lessonType: number) {
      const data = {
        controllingActionExternalId: controllingActionExternalId,
        lessonType: lessonType
      }
      return this.http.post<LessonModel>(this.baseUrl + '/LkoLessonSelectQuery', data);
    }

    public checkAttended(qrId: string) {
        return this.http.get<AttendedModel>(this.baseUrl + '/MarkItself/' + qrId);
    }

  /**
   * Получить все оценки физкультуры по студенту и семестру и дисциплине
   *
   * @param studentId Идентификатор студента
   * @param semester Номер семестра
   * @param subjectId Идентификатор предмета
   * @returns Физическая подготовка по студенту и семестру и дисциплине
   */
  public getByStudentInSemester(studentId: string, semester: number, subjectId: number): Observable<PhysTrainingMarkModel[]> {
    return this.http.get<PhysTrainingMarkModel[]>(this.baseUrl + `/GetByStudentInSemester/${studentId}/${semester}/${subjectId}`);
  }

  /**
   * Получить все дисциплины физкультуры по студенту и семестру
   *
   * @param studentId Идентификатор студента
   * @param semester Номер семестра
   * @returns Физический предмет по студенту и семестру
   */
  public getPhysSubjectByStudentInSemester(studentId: string, semester: number): Observable<PhysSubject[]> {
    return this.http.get<PhysSubject[]>(this.baseUrl + `/GetPhysSubjectByStudentInSemester/${studentId}/${semester}`);
  }

  /**
   * Получить все семестры физкультуры по студенту
   *
   * @param studentId Идентификатор студента
   * @returns Семестры по физической подготовке студента
   */
  public getSemestersByPhysTrainingMarkByStudent(studentId: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + `/GetSemestersByPhysTrainingMarkByStudent/${studentId}`);
  }
}
