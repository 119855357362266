<div class="screen">
    <div class="popup" *ngIf="successfully">
      <div class="center">
          <span class="k-icon item-icon k-i-check-circle green size33 m-l10"></span>
          <h1>Вы успешно отмечены</h1>
      </div>
  
      <div class="m-t10">
          <p class="title">Дисциплина:</p>
          <p class="text">{{ information.discipline }}</p>
      </div>
  
      <div class="m-t10">
          <p class="title">Тема:</p>
          <p class="text">{{ information.statementLesson }}</p>
      </div>
      
      <div class="m-t10">
          <p class="title">Дата занятия:</p>
          <p class="title m-l10">{{ information.date }}, {{ information.startTime.slice(12, 17) }} - {{ information.endTime.slice(12, 17) }}</p>
      </div>

      <div class="center">
        <button kendoButton (click)="route()">Закрыть</button>
      </div>
    </div>
  
    <div class="popup w-400" *ngIf="error">
      <div class="center">
        <span class="k-icon item-icon k-i-warning yellow size33 m-l10" *ngIf="isWarning"></span>
        <span class="k-icon item-icon k-i-x-circle red size33 m-l10" *ngIf="!isWarning"></span>
        <h1>{{ this.textError }}</h1>
        <button kendoButton (click)="route()">Закрыть</button>
      </div>
    </div>
</div>