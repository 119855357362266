<home-settings *ngIf="isAdmin"></home-settings>

<div class="grid-layout-container">
  <kendo-gridlayout class="studentLayout"
                    [align]="align"
                    [gap]="{ rows: 10, cols: 20}">
    <kendo-gridlayout-item class="profilePictureColumn">
      <div class="profilePicture" [style.background-image]="photoUrl" >
      </div>
      <h3 class="profilePictureColumn__name" *ngIf="!isHidden(enum.fio)">
        {{studentInfo?.fio ?? ""}}
      </h3>
      <!--<div class="contacts">
        <h2 class="contacts__title">Контакты</h2>
        <div class="email">
          <table class="table email">
            <tr class="spaceUnder">
              <th>E-mail</th>
            </tr>
          </table>
          <table class="table value">
            <tr class="spaceUnder">
              <td>{{emailValue}}</td>
            </tr>
          </table>
        </div>
        <div class="phone">
          <table class="table phone">
            <tr class="spaceUnder">
              <th>Телефон</th>
            </tr>
          </table>
          <table class="table value">
            <tr class="spaceUnder">
              <td>{{phoneFormat(phoneValue)}}</td>
            </tr>
          </table>
        </div>
        <div class="support">
          <h2 class="supportTitle">Техническая поддержка ЛК</h2>
          <table class="table" style="margin-top: 3px;">
            <tr class="spaceUnder">
              <td>E-mail  <a class="mailLink" href="mailto:{{supportEmail}}">{{supportEmail}}</a></td>
            </tr>
          </table>
        </div>
      </div>-->
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [col]="2" [colSpan]="4" [row]="2">
      <h1 style="margin-top: 0" class="mainInfoTitle">
        Основная информация
      </h1>
      <!-- <kendo-loader style="position: absolute; left: 50%" *ngIf="!hasLoaded" type="converging-spinner" size="medium"></kendo-loader> -->
      <table class="studentInfo">
        <tr *ngIf="!isHidden(enum.student_number)">
          <th>Номер зачетной книжки</th>
          <td>{{studentInfo?.studentNumber ?? "-"}}</td>
        </tr>
      </table>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="rightRow">
      <div class="contactTitle">
        <h2>Контакты
          <kendo-icon kendoTooltip
                      *ngIf="systemSettingIsHidden(systemEnum.student_can_edit_deanery_contacts)"
                      [tooltipWidth]="360"
                      [title]="systemSettingContactNotification.systemsSettingContactNotification || ''"
                      name="info"
                      tooltipClass="tooltip"
                      position="right"
                      size="medium">
          </kendo-icon>
        </h2>
        <button class="addContactBtn" *ngIf="isCanAddContact()" (click)="toAddContactPage()">
          Добавить контакт
          <span class="k-icon k-i-plus ml-8"></span>
        </button>
      </div>
      <table class="studentInfo contacts">
        <tr *ngIf="!isHidden(enum.phone) && getMobilePhones().length">
          <th>Мобильный телефон</th>
          <div class="content">
            <td *ngFor="let phone of getMobilePhones()" class="contentRow">
              <p class="cont mw-200" [ngClass]="{'fw-600': phone.isMain}">{{ phone.phoneNumber }}</p>
              <div class="contact-container">
                <p class="type">{{ getPhoneTypeName(phone.dictPhoneTypeId) }}</p>
                <button *ngIf="shouldShowEditButton(phone.addedByStudent, DisplaySystemSettingEnum.mobile_phone)"
                        (click)="toEditContactPage('phone', phone.externalId!)"
                        class="k-button-icon k-icon k-i-edit ng-star-inserted editBtn">
                </button>
              </div>
            </td>
          </div>
        </tr>
        <tr *ngIf="!isHidden(enum.city_phone) && getCityPhones().length">
          <th>Городской телефон</th>
          <div class="content">
            <td *ngFor="let phone of getCityPhones()" class="contentRow">
              <p class="cont mw-200">{{ phone.phoneNumber }}</p>
              <button *ngIf="shouldShowEditButton(phone.addedByStudent, DisplaySystemSettingEnum.city_phone)"
                      (click)="toEditContactPage('cityPhone', phone.externalId!)"
                      class="k-button-icon k-icon k-i-edit ng-star-inserted editBtn">
              </button>
            </td>
          </div>
        </tr>
        <tr *ngIf="!isHidden(enum.email) && studentInfo?.emails?.length">
          <th>E-mail</th>
          <div class="content">
            <td *ngFor="let email of getStudentEmails()" class="contentRow">
              <a class="mail-link mw-200" [ngClass]="{'fw-600': email.isMain}" href="mailto:{{ email.email }}">{{ email.email }}</a>
              <div class="contact-container">
                <p class="type">{{ getEmailTypeName(email.dictEmailTypeId) }}</p>
                <button *ngIf="shouldShowEditButton(email.addedByStudent, DisplaySystemSettingEnum.email)"
                        (click)="toEditContactPage('email', email.externalId!)"
                        class="k-button-icon k-icon k-i-edit ng-star-inserted editBtn">
                </button>
              </div>
            </td>
          </div>
        </tr>
        <tr *ngIf="!isHidden(enum.socialnetwork) && studentInfo?.socialNetworks?.length">
          <th>Соц. сети</th>
          <div class="content">
            <td *ngFor="let socialNetwork of studentInfo?.socialNetworks" class="contentRow">
              <a class="mail-link mw-200" href="{{ socialNetwork.socialNetwork }}" target="_blank">
                {{ socialNetwork.dictSocialNetworkId
                ? getDictSocialNetworkName(socialNetwork.dictSocialNetworkId)
                : getDomainFromUrl(socialNetwork.socialNetwork) }}
              </a>
              <button *ngIf="shouldShowEditButton(socialNetwork.addedByStudent, DisplaySystemSettingEnum.socialnetwork)"
                      (click)="toEditContactPage('social', socialNetwork.externalId!)"
                      class="k-button-icon k-icon k-i-edit ng-star-inserted editBtn">
              </button>
            </td>
          </div>
        </tr>
      </table>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [col]="2" [colSpan]="2" [row]="3">
      <div class="educationTitle">
        <h2>Информация об обучении</h2>
      </div>
      <!--<div *ngIf="shortInformation">
        <table class="studentInfo">
            <tr>
                <th>Номер зачетной книжки</th>
                <td>{{studentNumberValue}}</td>
            </tr>
            <tr>
                <th>Источник финансирования</th>
                <td *ngIf="hasLoaded">
                  {{budgetStateValue}}
                </td>
            </tr>
            <tr>
                <th>Форма обучения</th>
                <td>{{studyFormValue}}</td>
            </tr>
        </table>
      </div>-->
      <div *ngIf="!shortInformation">
        <table class="studentInfo">
          <tr *ngIf="!isHidden(enum.academic_state)">
            <th>Академический статус</th>
            <td>{{ studentInfo?.academicStateName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.faculty)">
            <th>Институт/факультет</th>
            <td>{{ studentInfo?.facultyName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.course)">
            <th>Курс</th>
            <td>{{ studentInfo?.courseNum ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.group)">
            <th>Группа</th>
            <td>{{ studentInfo?.groupName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.education_standard)">
            <th>Направление подготовки</th>
            <td>{{ studentInfo?.educationStandardName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.study_form)">
            <th>Форма обучения</th>
            <td>{{ studentInfo?.studyFormName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.training_level)">
            <th>Уровень подготовки</th>
            <td>{{ studentInfo?.trainingLevelName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.department)">
            <th>Кафедра</th>
            <td>{{ studentInfo?.departmentName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.education_plan)">
            <th>Учебный план</th>
            <td>{{ studentInfo?.educationPlanName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.education_program)">
            <th>Профиль (программа)</th>
            <td>{{ studentInfo?.educationProgramName ?? "-" }}</td>
          </tr>
        </table>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="fundingInformation" *ngIf="showFilter">
      <div class="educationTitle">
        <h2>Сведения о финансировании</h2>
      </div>
      <div *ngIf="!shortInformation">
        <table class="studentInfo">
          <tr *ngIf="!isHidden(enum.budget)">
            <th>Источник финансирования</th>
            <td>{{ studentInfo?.studentBudgetName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.target_training_name) && studentInfo?.targetTrainingName">
            <th>Целевое обучение</th>
            <td>{{studentInfo?.targetTrainingName}}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.target_training_customer) && studentInfo?.targetTrainingCustomer">
            <th>Заказчик целевого обучения</th>
            <td>{{studentInfo?.targetTrainingCustomer}}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.budget_category)">
            <th>Категория финансирования</th>
            <td>{{ studentInfo?.budgetCategoryName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.budget_sub_category)">
            <th>Подкатегория финансирования</th>
            <td>{{ studentInfo?.budgetSubCategoryName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.paid_training_agreement)">
            <th>Договор на платное обучение</th>
            <td>{{ studentInfo?.paidContractNum ?? "-" }}</td>
          </tr>
        </table>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="additionalData" *ngIf="showFilter">
      <div class="educationTitle">
        <h2>Прочее</h2>
      </div>
      <div *ngIf="!shortInformation">
        <table class="studentInfo">
          <tr *ngIf="!isHidden(enum.languages)">
            <th>Изучаемые языки</th>
            <td>{{studentInfo?.languages ?? "-"}}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.elder)">
            <th>Староста</th>
            <td>{{ studentInfo?.elder ? "Да" : "Нет" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.score_number)">
            <th>Номер счета</th>
            <td>{{ studentInfo?.buhCode ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.pedagogical_head)">
            <th>Руководитель педагогической практики</th>
            <td>{{ studentInfo?.practicePersonName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.external_id)">
            <th>Внутренний номер (для тех.поддержки)</th>
            <td>{{ studentInfo?.studentId ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.scientific_adviser_tutor)">
            <th>Научный руководитель (куратор)</th>
            <td>{{ studentInfo?.scientificPersonName ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.diploma_average)">
            <th>Средний балл (общий)</th>
            <td>{{ studentInfo?.diplomaAverage ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.average_score_semester)">
            <th>Средний балл по итогам семестра</th>
            <td>{{ studentInfo?.semesterResult ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.admission_year)">
            <th>Год начала обучения</th>
            <td>{{ studentInfo?.dateStart | date:'yyyy' ?? "-" }}</td>
          </tr>
          <tr *ngIf="!isHidden(enum.graduate_year)">
            <th>Год выпуска</th>
            <td>{{ studentInfo?.dateFinish | date:'yyyy' ?? "-" }}</td>
          </tr>
        </table>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="turnBtn">
      <div
        class="used-filter-list__title"
        (click)="showUsedFiltersBySetting()"
      >
        <h2 class="used-filter-list__title-text">{{showFilter ? "Свернуть данные" : "Все данные"}}</h2>
        <button
          kendoButton
          [ngClass]="arrowButton"
          fillMode="clear"
          icon="chevron-down"
          class="used-filter-list__title-btn"
          id="used-filter-list-arrow"
        ></button>
      </div>
      <div *ngIf="!shortInformation">
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="infoBlock">
      <h3 class="infoMsg" [innerHTML]="infoMessage">
      </h3>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item *ngIf="!hidden" [row]="3" [col]="2" [colSpan]="5" style="margin-top: 13px">
      <h2 style="margin-bottom: 20px;" [ngClass]="shortInformation ? 'marginForShort':'marginForLong'">
        Расписание занятий на сегодня - {{currentDate | date:'dd.MM.YYYY'}} {{"(" + dayName + ")"}}
      </h2>
      <div class="dataBlock">
        <div class="discipline onGoing">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
        <div class="discipline">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
        <div class="discipline">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
      </div>
      <h2 style="margin-top: 30px; margin-bottom: 20px;">
        Объявления
      </h2>
      <div class="dataBlock">
        <div class="eventItem">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
        <div class="eventItem hasViewed">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
        <div class="eventItem hasViewed">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
