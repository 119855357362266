import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import {TokenStorageService} from "../services/token.service";
import {environment} from '../../environments/environment';
import {Location} from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private jwtHelper: JwtHelperService, private router: Router, private tokenStore: TokenStorageService, private location: Location,
    ) {

  }

  canActivate(){
    const token = this.tokenStore.getAuthToken()
    let user;
    let role;
    if (token !== null) {
      user = this.jwtHelper.decodeToken(token);
      role = user.role;
    }

    if (token && !this.jwtHelper.isTokenExpired(token) && 
    user.stud_person_id !== undefined){
      return true
    }

    if (!token || this.jwtHelper.isTokenExpired(token) || !user.person_id) {
      localStorage.setItem('last_url', this.location.path());
    }

    if(role?.includes('admin'))
      return true;

    if (user.stud_person_id === undefined) {
      alert(environment.invalidTokenMessage);
    }

    localStorage.removeItem('switchStudent');
    localStorage.removeItem('changeStudent');

    this.router.navigateByUrl("/login")
    return false;
  }
}
